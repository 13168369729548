<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 22L12 12M22 2L12 12M12 12L2 2M12 12L22 22"
      stroke="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "CustomCloseIcon",
};
</script>
